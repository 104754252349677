import React, { useState, useEffect, useRef, ReactNode } from 'react';

interface CarouselProps {
  children: ReactNode[];
  dotColor?: string;
  activeDotColor?: string;
  slideIntervalMs?: number;
  pauseOnHover?: boolean;
}

const Carousel: React.FC<CarouselProps> = ({
  children,
  dotColor = 'bg-gray-300',
  activeDotColor = 'bg-blue-500',
  slideIntervalMs = 3000,
  pauseOnHover = true,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const itemCount = React.Children.count(children);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const nextSlide = () => {
    setActiveIndex(prev => (prev === itemCount - 1 ? 0 : prev + 1));
  };

  useEffect(() => {
    const startTimer = () => {
      timerRef.current = setInterval(() => {
        nextSlide();
      }, slideIntervalMs);
    };

    if (!isPaused) {
      startTimer();
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [isPaused, slideIntervalMs, itemCount]);

  const slideWidth = Math.min(containerWidth, 1200); // Maximum width of 1200px
  const slidePadding = 32; // Padding between slides
  const totalOffset = activeIndex * (slideWidth + (slidePadding * 2));

  return (
    <div className="flex flex-col w-full h-full ">
      {/* Carousel container */}
      <div 
        ref={containerRef}
        className="w-full flex-grow relative overflow-hidden"
        onMouseEnter={() => pauseOnHover && setIsPaused(true)}
        onMouseLeave={() => pauseOnHover && setIsPaused(false)}
      >
        {/* Slides container */}
        <div
          className="flex absolute h-full w-full"
          style={{
            transform: `translateX(calc(${(containerWidth - slideWidth) / 2}px - ${totalOffset}px))`,
            transition: 'transform 800ms ease-out',
            gap: `${slidePadding * 2}px`
          }}
        >
          {React.Children.map(children, (child, index) => (
            <div 
              key={index} 
              className="flex-shrink-0 transition-all duration-300 h-full"
              style={{ 
                width: `${slideWidth}px`,
                opacity: index === activeIndex ? 1 : 0.3,
                transform: `scale(${index === activeIndex ? 1 : 0.95})`,
              }}
            >
              {child}
            </div>
          ))}
        </div>
      </div>
      
      {/* Dots navigation */}
      <div className="flex justify-center gap-2 py-4 flex-shrink-0">
        {Array.from({ length: itemCount }, (_, i) => (
          <button
            key={i}
            type="button"
            aria-label={`Go to slide ${i + 1}`}
            className={`h-2 rounded-full transition-all ${
              i === activeIndex 
                ? `${activeDotColor} w-4` 
                : `${dotColor} w-2`
            }`}
            onClick={() => {
              setActiveIndex(i);
              if (timerRef.current) {
                clearInterval(timerRef.current);
              }
              setIsPaused(true);
              setTimeout(() => setIsPaused(false), 2000);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;