import { Tooltip } from "react-tooltip";

interface Person {
  source: string;
  credit: string;
}

interface TripleImageCardProps {
  personA: Person;
  personB: Person;
  kid: string;
  isFocused: boolean;
}

const TripleImageCard: React.FC<TripleImageCardProps> = ({
  personA,
  personB,
  kid,
  isFocused,
}) => {
  const tooltipStyle = {
    maxWidth: "150px",
    whiteSpace: "normal" as const,
    wordBreak: "break-word" as const,
  };

  return (
    <div
      className={`bg-black rounded-lg shadow-md transition-all duration-300 h-full flex items-center ${
        isFocused ? "scale-100" : "scale-95"
      }`}
    >
      {/* Aspect ratio container - limits width based on height */}
      <div className="aspect-[3/2] h-full w-full relative">
        {/* Content container */}
        <div className="absolute inset-0 flex p-1">
          {/* Left column - takes 1/3 of the width */}
          <div className="w-1/3 h-full flex flex-col justify-between pr-1">
            {/* Top square */}
            <div className="flex-1 relative mb-1">
              <div className="absolute inset-0 rounded-lg overflow-hidden">
                <img
                  src={personA.source}
                  alt="Person A"
                  className="w-full h-full object-cover"
                  data-tooltip-id="personA-tooltip"
                  data-tooltip-content={personA.credit}
                />
              </div>
            </div>
            {/* Bottom square */}
            <div className="flex-1 relative">
              <div className="absolute inset-0 rounded-lg overflow-hidden">
                <img
                  src={personB.source}
                  alt="Person B"
                  className="w-full h-full object-cover"
                  data-tooltip-id="personB-tooltip"
                  data-tooltip-content={personB.credit}
                />
              </div>
            </div>
          </div>

          {/* Right square - takes 2/3 of the width */}
          <div className="w-2/3 h-full relative">
            <div className="absolute inset-0 rounded-lg overflow-hidden">
              <img 
                src={kid} 
                alt="Child" 
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>

      <Tooltip id="personA-tooltip" place="top" style={tooltipStyle} />
      <Tooltip id="personB-tooltip" place="top" style={tooltipStyle} />
    </div>
  );
};

export default TripleImageCard;