import { useEffect } from "react";
import { motion } from "framer-motion";
import { Switch, Route, useLocation } from "wouter";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TripleImageCard from "./components/TripleImageCard";
import Carousel from "./components/Carousel";
import { usePostHog } from "posthog-js/react";

const AppStoreButton = () => {
  const posthog = usePostHog();
  return (
    <motion.a
      href="https://apps.apple.com/us/app/aifacemix/id6736365137"
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center justify-center mb-2 md:mb-0 rounded-lg p-0 h-[100%]"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      transition={{ type: "spring", stiffness: 400, damping: 17 }}
      onClick={() => {
        posthog?.capture("app_store_click", {
          location: "main_page",
        });
      }}
    >
      <img
        src={`${process.env.PUBLIC_URL}/assets/images/logo_appstore.svg`}
        alt="Download on the App Store"
        className="w-40 md:w-60"
      />
    </motion.a>
  );
};

const App = () => {
  const location = useLocation();
  const posthog = usePostHog(); // Add hook

  // Track page views
  useEffect(() => {
    posthog?.capture("$pageview");
  }, [location, posthog]);

  const getImagePath = (folder: string, imageName: string) => {
    return `${process.env.PUBLIC_URL}/assets/images/examples/${folder}/${imageName}`;
  };
  const examples = [
    {
      folder: "ex1",
      personA: {
        source: getImagePath("ex1", "PersonA_licenced.png"),
        credit:
          "Justin Bieber by Rudy Mancuso, cropped, licensed under [CC BY 3.0](https://creativecommons.org/licenses/by/3.0/)",
      },
      personB: {
        source: getImagePath("ex1", "PersonB_licenced.png"),
        credit:
          "Hailey Baldwin by VOGUE Taiwan, cropped, licensed under [CC BY 3.0](https://creativecommons.org/licenses/by/3.0/)",
      },
      kid: getImagePath("ex1", "kid2.jpg"),
    },
    {
      folder: "ex2",
      personA: {
        source: getImagePath("ex2", "PersonA_licenced.png"),
        credit:
          "Dwayne Johnson by Eva Rinaldi, licensed under [CC BY-SA 2.0](https://creativecommons.org/licenses/by-sa/2.0/)",
      },
      personB: {
        source: getImagePath("ex2", "PersonB_licenced.png"),
        credit:
          "Margot Robbie by VOGUE Taiwan, licensed under [CC BY 3.0](https://creativecommons.org/licenses/by/3.0/)",
      },
      kid: getImagePath("ex2", "kid1.jpg"),
    },
    {
      folder: "ex3",
      personA: {
        source: getImagePath("ex3", "PersonA_licenced.png"),
        credit:
          "Kim Kardashian by Eva Rinaldi, cropped, licensed under [CC BY-SA 2.0](https://creativecommons.org/licenses/by-sa/2.0/)",
      },
      personB: {
        source: getImagePath("ex3", "PersonB_licenced.png"),
        credit: "", // No credit for person B in this example
      },
      kid: getImagePath("ex3", "kid1.jpg"),
    },
    {
      folder: "ex7",
      personA: {
        source: getImagePath("ex7", "PersonA_licenced.png"),
        credit:
          "Taylor Swift by iHeartRadioCA, licensed under [CC BY 3.0](https://creativecommons.org/licenses/by/3.0/)",
      },
      personB: {
        source: getImagePath("ex7", "PersonB_licenced.png"),
        credit:
          "Travis Kelce by All-Pro Reels, licensed under [CC BY-SA 2.0](https://creativecommons.org/licenses/by-sa/2.0/)",
      },
      kid: getImagePath("ex7", "kid4.jpg"),
    },
    {
      folder: "ex5",
      personA: {
        source: getImagePath("ex5", "PersonA_licenced.png"),
        credit:
          "Selena Gomez by Frank Sun, licensed under [CC BY-SA 4.0](https://creativecommons.org/licenses/by-sa/4.0/)",
      },
      personB: {
        source: getImagePath("ex5", "PersonB_licenced.png"),
        credit:
          "Brad Pitt by Glenn Francis, licensed under [CC BY-SA 4.0](https://creativecommons.org/licenses/by/4.0/)",
      },
      kid: getImagePath("ex5", "kid1.jpg"),
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 2,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 24,
      },
    },
  };

  const Home = () => {
    const posthog = usePostHog(); // Add hook

    useEffect(() => {
      posthog?.capture('home_page_view');
    }, [posthog]);
    
    return (
      <motion.div
        className="flex flex-col min-h-screen bg-black text-white"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {/* Header - fixed height */}
        <motion.header
          className="flex items-center justify-center h-[120px] py-8"
          variants={itemVariants}
        >
          <div className="flex items-center">
            <motion.img
              src="/assets/images/icon.png"
              alt="Face Mix Icon"
              className="w-20 h-20 mr-3"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
              }}
            />
            <motion.h1 className="text-5xl font-bold" variants={itemVariants}>
              Face Mix
            </motion.h1>
          </div>
        </motion.header>

        {/* Main content - takes remaining space and centers content */}
        <motion.main
          className="flex-1 flex flex-col items-center justify-center w-full px-4 py-8 gap-12"
          variants={containerVariants}
        >
          {/* Carousel section - fixed proportion of viewport height */}
          <motion.div
            className="w-full max-w-5xl relative"
            variants={itemVariants}
            style={{ height: "50vh" }}
          >
            <div className="w-full h-full">
              <Carousel slideIntervalMs={3000} dotColor="bg-gray-300" activeDotColor="bg-blue-500">
                {examples.map((example, index) => (
                  <TripleImageCard
                    key={index}
                    personA={example.personA}
                    personB={example.personB}
                    kid={example.kid}
                    isFocused={true}
                  />
                ))}
              </Carousel>
            </div>
          </motion.div>

          {/* Text and button section */}
          <div className="flex flex-col items-center gap-8">
            <motion.p className="text-center text-xl" variants={itemVariants}>
              Upload your pictures and see how
              <br />
              the kids will look like.
            </motion.p>

            <div className="flex justify-center">
              <AppStoreButton />
            </div>
          </div>
        </motion.main>

        {/* Footer - fixed height */}
        <motion.footer
          className="h-[80px] w-full px-2 py-2 bg-black text-gray-500 flex flex-col md:flex-row justify-between items-center md:px-8"
          initial={{ y: 100 }}
          animate={{ y: 0 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20,
            delay: 0.5,
          }}
        >
          <p className="text-center md:text-left text-sm mb-2 md:mb-0 md:flex-1">
            © FaceMix 2024. All rights reserved.
          </p>

          <div className="flex flex-col items-center md:items-end space-y-1 md:flex-1">
            <motion.a
              href="/legal"
              className="text-gray-400 hover:text-white text-sm"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Terms & Conditions
            </motion.a>
            <motion.a
              href="mailto:aifacemix@gmail.com"
              className="text-gray-400 hover:text-white text-sm"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              aifacemix@gmail.com
            </motion.a>
          </div>
        </motion.footer>
      </motion.div>
    );
  };

  return (
    <div>
      <Switch>
        <Route path="/legal" component={PrivacyPolicy} />
        <Route path="/" component={Home} />
      </Switch>
    </div>
  );
};

export default App;
