import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-8 max-w-3xl">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
      <p className="mb-4">
        <strong>Effective Date: 9 Oct. 2024</strong>
      </p>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">1. Introduction</h2>
        <p>
          We value your privacy and are committed to protecting the personal
          information you share with us. This Privacy Policy explains what
          information we collect, how we use it, and your rights regarding your
          data when you use our mobile application, FaceMix.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">
          2. Information We Collect
        </h2>
        <ul className="list-disc pl-6">
          <li className="mb-2">
            <strong>Device Tokens:</strong> When you use our app, we may collect
            and store your device's unique token for the purpose of sending push
            notifications.
          </li>
          <li className="mb-2">
            <strong>User Images and Face Data:</strong> Our app allows you to
            upload images, which may include face data. These images are
            securely processed and used solely for the purpose of providing the
            app's features, such as finetuning a model on Replicate to generate
            new images based on your inputs.
          </li>
        </ul>
      </section>
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">3. Face Data Usage</h2>
        <p>
          Our app, FaceMix, uses face data to provide its core functionality of
          generating new images based on user inputs. We want to be clear about
          how we handle this sensitive information:
        </p>
        <ul className="list-disc pl-6">
          <li className="mb-2">
            <strong>Face Data Retention:</strong> We do not retain face data
            beyond the immediate processing required for image generation. Once
            the generated images are sent to you, all face data is immediately
            and permanently deleted from our systems.
          </li>
          <li className="mb-2">
            <strong>Reasons for Using Face Data:</strong> Face data is used
            solely for the purpose of finetuning models on Replicate to generate
            new images based on your inputs. This is essential for providing the
            app's primary feature.
          </li>
          <li className="mb-2">
            <strong>Storage Duration:</strong> Face data is stored only for the
            duration of the image generation process, which typically takes a
            few minutes. We store it for this short period to ensure successful
            completion of the image generation task.
          </li>
          <li className="mb-2">
            <strong>Third-Party Sharing:</strong> We share face data with
            Replicate, our image generation service provider. This sharing is
            necessary to process the images and create the desired output.
          </li>
          <li className="mb-2">
            <strong>Reason for Third-Party Sharing:</strong> Sharing with
            Replicate is essential for the core functionality of our app, as
            they provide the AI models and processing power needed for image
            generation.
          </li>
          <li className="mb-2">
            <strong>Third-Party Storage Practices:</strong> Replicate processes
            the face data as part of their service. Users can request data
            deletion from them. For more information on Replicate's privacy
            practices, please visit
            <a
              href="https://replicate.com/privacy"
              className="text-blue-600 hover:underline"
            >
              https://replicate.com/privacy
            </a>
            .
          </li>
        </ul>
      </section>
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">
          3. How We Use Your Information
        </h2>
        <ul className="list-disc pl-6">
          <li className="mb-2">
            <strong>Device Tokens:</strong> We collect device tokens to send you
            push notifications, including but not limited to updates on app
            features, service alerts, or promotional messages.
          </li>
          <li className="mb-2">
            <strong>User Images and Face Data:</strong> The images you upload,
            including any face data, are processed on Replicate. We do not use
            your images or face data for any other purpose and will never share
            them with third parties, except as necessary for the functionality
            of the app (i.e., with Replicate for image processing).
            <a
              href="https://replicate.com/privacy"
              className="text-blue-600 hover:underline"
            >
              https://replicate.com/privacy
            </a>
            .
          </li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">4. Data Security</h2>
        <p>
          We implement appropriate security measures to protect your data from
          unauthorized access, disclosure, alteration, or destruction. All data
          transmission is encrypted using industry-standard SSL (Secure Sockets
          Layer) technology. Images and device tokens are stored securely, and
          access to this information is restricted to authorized personnel only.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">5. Data Sharing</h2>
        <p>
          We do not sell or share your device tokens or images with third
          parties, except where required by law or as necessary to provide the
          app's core functionality (e.g., finetuning models using Replicate or
          sending notifications).
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">6. User Rights</h2>
        <p>You have the right to:</p>
        <ul className="list-disc pl-6">
          <li className="mb-2">
            <strong>Access Your Data:</strong> You may request a copy of the
            data we store about you.
          </li>
          <li className="mb-2">
            <strong>Delete Your Data:</strong> You may request that we delete
            your stored images or device tokens. Note that this may affect your
            ability to use certain features of the app.
          </li>
          <li className="mb-2">
            <strong>Opt-Out of Notifications:</strong> You can manage or opt-out
            of receiving push notifications at any time by adjusting your device
            settings or app preferences.
          </li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">
          7. How Long We Retain Your Data
        </h2>
        <ul className="list-disc pl-6">
          <li className="mb-2">
            <strong>Device Tokens:</strong> We store device tokens for as long
            as necessary to provide push notification services.
          </li>
          <li className="mb-2">
            <strong>User Images and Face Data:</strong> Images and face data are
            stored only for the duration of the image generation process,
            typically a few minutes. They are deleted immediately after the
            generated images are sent to you.
          </li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">
          8. Changes to This Privacy Policy
        </h2>
        <p>
          We may update this Privacy Policy from time to time. When we do, we
          will notify you of any significant changes through the app or via
          email. The most current version will always be available on our
          website or app.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">9. Contact Us</h2>
        <p>
          If you have any questions or concerns about our Privacy Policy or how
          we handle your data, please contact us at:
        </p>
        <p>
          Email:{" "}
          <a
            href="mailto:aifacemix@gmail.com"
            className="text-blue-600 hover:underline"
          >
            aifacemix@gmail.com
          </a>
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
